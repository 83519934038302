import React from "react";
import ReactDOM from "react-dom";
import Preloader from "./components/preloader/preloader";
import Timer from "./components/countdown/timer";

import "./styles.css";

function App() {
  return (
    <div className="App">
      <div className="container">
        <h1>
          Website update
          <br />
          Coming Soon
        </h1>
        <Timer />
        <Preloader />
      </div>
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);


// import React from 'react';
// import ReactDOM from 'react-dom';
// import {BrowserRouter} from 'react-router-dom';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// const app = (
//   <React.StrictMode>
//       <BrowserRouter>
//           <App />
//       </BrowserRouter>
//   </React.StrictMode>
// );

// ReactDOM.render(app, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();